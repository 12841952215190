
.main-wrapper{
    padding: 0 20px;
    .top-content{
        position: relative;
        .btn-back{
            position: absolute;
            // top: -12px;
            right: 20px;
        }
    }
    .answer-title{
        padding: 20px 0;
        margin: 0;
        font-size: 24px;
    }
}

